import React from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = () => {
  const series = [
    {
      name: "Expenses",
      data: [1000, 2000, 3000],
      gap: 1000,
    },
  ];

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      fontFamily: "Manage Medium",
    },
    colors: ["#ef4444"], // Change the color here
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#ef4444"], // Change the color of the stroke here
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#ef4444"], // Gradient color (same as the main color)
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },
    title: {
      text: "Expense Summary",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#000000",
      },
    },
    labels: ["Jan", "Feb", "March", "April", "May", "June"],
    xaxis: {
      type: "",
      style: {
        fontSize: "18px",
        fontWeight: "500",
        color: "#6b7280",
      },
    },
    yaxis: {
      opposite: false,
      style: {
        color: "#6b7280",
        fontSize: "12px",
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  };

  return (
    <div>
      <div id="chart" style={{ fontFamily: "text-medium" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default AreaChart;
