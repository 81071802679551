const forms = [
  {
    name: "Sent",
    options: [
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Purchase sent date",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
];

export default forms;
