const tables = [
  {
    name: "Date",
  },
  {
    name: "Batch",
  },
  // {
  //   name: "Stock",
  // },
  {
    name: "Branch",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];

export default tables;
