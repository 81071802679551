import React from "react";

const SettingIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 48.4 48.4"
    >
      <g>
        <path
          d="M48.4 24.2c0-1.8-1.297-3.719-2.896-4.285s-3.149-1.952-3.6-3.045c-.451-1.093-.334-3.173.396-4.705.729-1.532.287-3.807-.986-5.08-1.272-1.273-3.547-1.714-5.08-.985-1.532.729-3.609.848-4.699.397s-2.477-2.003-3.045-3.602C27.921 1.296 26 0 24.2 0c-1.8 0-3.721 1.296-4.29 2.895s-1.955 3.151-3.045 3.602c-1.09.451-3.168.332-4.7-.397-1.532-.729-3.807-.288-5.08.985-1.273 1.273-1.714 3.547-.985 5.08s.845 3.611.392 4.703c-.453 1.092-1.998 2.481-3.597 3.047S0 22.4 0 24.2s1.296 3.721 2.895 4.29c1.599.568 3.146 1.957 3.599 3.047.453 1.089.335 3.166-.394 4.698s-.288 3.807.985 5.08c1.273 1.272 3.547 1.714 5.08.985s3.61-.847 4.7-.395c1.091.452 2.476 2.008 3.045 3.604S22.4 48.4 24.2 48.4c1.8 0 3.721-1.295 4.29-2.891.568-1.596 1.953-3.15 3.043-3.604 1.09-.453 3.17-.334 4.701.396 1.533.729 3.808.287 5.08-.985 1.273-1.273 1.715-3.548.986-5.08-.729-1.533-.849-3.61-.398-4.7.451-1.09 2.004-2.477 3.603-3.045C47.104 27.921 48.4 26 48.4 24.2zm-24.2 8.88c-4.91 0-8.88-3.97-8.88-8.87 0-4.91 3.97-8.88 8.88-8.88 4.899 0 8.87 3.97 8.87 8.88 0 4.9-3.97 8.87-8.87 8.87z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default SettingIcon;
