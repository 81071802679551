import React from "react";

const InventoryIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 32 32"
    >
      <g transform="matrix(1.1199999999999992,0,0,1.1199999999999992,-1.9199999999999875,-1.9199999999999875)">
        <path
          d="M2 24a1 1 0 0 0 .586.91L13.446 30l.053-13.68L2 11.011zM20.037 13.764 14.5 16.321 14.446 30l10.968-5.09A1 1 0 0 0 26 24v-9.295a6.957 6.957 0 0 1-5.963-.94zM17 8c0-.566.075-1.115.203-1.642l-2.79-1.268a.994.994 0 0 0-.827 0S2.532 10.126 2.503 10.143L14 15.45l5.176-2.389A6.974 6.974 0 0 1 17 8z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
        <path
          d="M24 2a6 6 0 1 0 0 12 6 6 0 0 0 0-12zm2.464 3.686-2 5a.5.5 0 0 1-.818.168l-2-2a.5.5 0 1 1 .708-.708l1.463 1.464 1.719-4.296a.5.5 0 1 1 .928.372z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
          class=""
        ></path>
      </g>
    </svg>
  );
};

export default InventoryIcon;
