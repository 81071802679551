import React from "react";

const BillingIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 511.988 511.988"
    >
      <g>
        <path
          d="M334.194 98.188v31.399c0 24.813-20.187 45-45 45H128.527c-24.813 0-45-20.187-45-45V98.189c-19.752 5.615-34.266 23.806-34.266 45.334v321.333c0 25.989 21.144 47.133 47.133 47.133h224.934c25.989 0 47.133-21.144 47.133-47.133V143.522c0-21.528-14.514-39.719-34.267-45.334zm-189.6 333.467c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm0-64.267c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm64.267 64.267c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm0-64.267c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm64.266 64.267c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm0-64.267c-8.28 0-15-6.72-15-15s6.72-15 15-15 15 6.72 15 15-6.72 15-15 15zm31.067-79.266c0 8.284-6.716 15-15 15H128.527c-8.284 0-15-6.716-15-15v-64.268c0-8.284 6.716-15 15-15h160.667c8.284 0 15 6.716 15 15z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M143.527 238.854h130.667v34.268H143.527zM398.461 29.988c-26.363 0-49.007 15.881-58.918 38.593 33.767 8.209 58.918 38.685 58.918 74.942v137.802l17.133-8.566 25.426 12.713a15.02 15.02 0 0 0 17.543-3.035c2.748-2.841 4.165-6.716 4.165-10.669V94.254c-.001-35.493-28.774-64.266-64.267-64.266z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
        <path
          d="M128.527 144.588h160.667c8.284 0 15-6.716 15-15V94.254c0-49.342 38.108-89.945 86.437-93.938L199.854 0c-48.276 4.046-86.327 44.622-86.327 93.926V129.588c0 8.284 6.716 15 15 15z"
          fill="currentColor"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
};

export default BillingIcon;
