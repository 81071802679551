import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Purchases/Paid/forms";
import headers from "../../../../Data/Forms/Purchases/Paid/headers";
import purchases from "../../../../Data/purchases.json";

const PurchasesPaidForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const fetchData = async () => {
    let purchase_options = [];
    purchases?.map((value, index) => {
      purchase_options?.push({
        value: value?.number,
        label: value?.number,
      });
    });

    values[0].options[0].form.options = purchase_options;
    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default PurchasesPaidForm;
