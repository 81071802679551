import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import headers from "../../../../Data/Tables/Settings/Settings/headers";
import Menus from "../../../../Componets/Menus";
import UsersIcon from "../../../../Assets/Svg/users";
import BranchIcon from "../../../../Assets/Svg/branch";
import SettingIcon from "../../../../Assets/Svg/settings";
import ProductIcon from "../../../../Assets/Svg/products";
import ExpenseIcon from "../../../../Assets/Svg/expense";

const SettingsTable = () => {
  let header = headers;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const menu = [
    {
      icon: <SettingIcon />,
      name: "General",
      description: "View and update your details",
      url: "/app/settings/general",
    },
    {
      icon: <BranchIcon />,
      name: "Branches",
      description: "View  create & update branch details",
      url: "/app/settings/branches",
    },
    {
      icon: <UsersIcon />,
      name: "Users",
      description: "View  create & update user details",
      url: "/app/settings/users",
    },
    {
      icon: <UsersIcon />,
      name: "User roles",
      description: "View , create & update user role",
      url: "/app/settings/roles",
    },
  ];

  return (
    <Header header={header} formdata={formdata}>
      <div className="mt-4 grid grid-cols-4 gap-4">
        {menu?.map((value, index) => {
          return (
            <Menus
              icon={value?.icon}
              name={value?.name}
              description={value?.description}
              url={value?.url}
            />
          );
        })}
      </div>
    </Header>
  );
};

export default SettingsTable;
