import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const Menus = (props) => {
  let icon = props?.icon;
  let name = props?.name;
  let description = props?.description;
  let url = props?.url;

  const navigate = useNavigate();

  const handleClick = () => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div
      className="flex bg-white p-4 rounded-lg border cursor-pointer setting-card hover:bg-gray-100"
      onClick={handleClick}
    >
      <div className="p-3 rounded-lg bg-gray-300">
        <div className="my-auto">{icon}</div>
      </div>
      <div className="px-2">
        <div>{name}</div>
        <div className="text-xs text-gray-400">{description}</div>
      </div>
    </div>
  );
};

export default Menus;
