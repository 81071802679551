import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Inventory/Batch/forms";
import headers from "../../../../Data/Forms/Inventory/Batch/headers";
import products from "../../../../Data/products.json";
import inventory from "../../../../Data/inventory.json";

const InventoryBatchForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const fetchData = async () => {
    let products_options = [];

    products?.map((value, index) => {
      products_options?.push({ value: value?.name, label: value?.name });
    });

    let product = inventory?.filter((item) => item.id == id);

    console.log(product, "product");

    console.log("products", products);
    values[0].options[1].form.options = products_options;

    if (id) {
      values[0].options[0].form.value = product?.[0]?.batch_number;
      values[0].options[1].form.value = get_product(product?.[0]?.product);

      values[1].options[0].form.value = product?.[0]?.purchase_price;
      values[1].options[1].form.value = product?.[0]?.sale_price;
      values[1].options[2].form.value = product?.[0]?.tax;

      values[2].options[0].form.value = product?.[0]?.stock;
      values[2].options[1].form.value = parseDate(
        product?.[0]?.manufacturing_date
      );
      values[2].options[2].form.value = parseDate(product?.[0]?.expiry_date);
      values[2].options[3].form.value = product?.[0]?.barcode;
    }
    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default InventoryBatchForm;
