import {
  BankOutlined,
  FileWordFilled,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import Authform from "../../../Componets/Authform";

const Signup = () => {
  const auth = {
    name: "Sign Up",
    button: "Create",
    link: {
      name: "Signin",
      path: "/",
      message: "Already have an account ? ",
    },
    options: [
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "username",
          placeholder: "Name",
          icon: <UserOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Company",
          icon: <BankOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Phone",
          icon: <PhoneOutlined className="text-gray-500" rotate={90} />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          icon: <MailOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "password",
        form: {
          type: "text",
          name: "password",
          placeholder: "Password",
          show: false,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "country",
          placeholder: "Country",
          icon: <GlobalOutlined className="text-gray-500" />,
          size: "large",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  };

  const formdata = (data) => {
    console.log(data);
  };

  return (
    <div className="bg-gray-100">
      <Authform auth={auth} formdata={formdata} />
    </div>
  );
};

export default Signup;
