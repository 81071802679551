import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Inventory/Transfer/tables";
import headers from "../../../../Data/Tables/Inventory/Transfer/headers";
import transfer from "../../../../Data/transfer.json";
import branches from "../../../../Data/branch.json";
import Status from "../../../../Componets/Status/status";
import ActionButton from "../../../../Componets/Actionbutton";

const InventoryTransferTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const get_branch = (id) => {
    let branch = "";
    branches?.map((value, index) => {
      if (id == value?.id) {
        branch = value?.name;
      }
    });

    return branch;
  };

  const fetchData = async (tableData) => {
    try {
      let data = [];

      if (tableData) {
        tableData?.map((value, index) => {
          data?.push({
            Number: "#TR-" + value?.id,
            Date: value?.date,
            Branch: get_branch(value?.branch),
            Amount: value?.amount,
            Status: <Status status={value?.status} />,
            Action: <ActionButton edit="/" delete="/" />,
          });
        });
      } else {
        transfer?.map((value, index) => {
          data?.push({
            Number: "#TR-" + value?.id,
            Date: value?.date,
            Branch: get_branch(value?.branch),
            Amount: value?.amount,
            Status: <Status status={value?.status} />,
            Action: <ActionButton edit="/" delete="/" />,
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...transfer];

    let search = data?.search;
    let branch = data?.sort;
    let status = data?.status;

    if (search) {
      tableData = tableData.filter((item) =>
        String(item?.id)?.includes(search)
      );
    }

    if (branch) {
      tableData = tableData.filter((item) => item.branch === branch);
    }

    if (status || status == 0) {
      tableData = tableData.filter((item) => item.status === status);
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default InventoryTransferTable;
