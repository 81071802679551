import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import Table from "../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../Data/Tables/Customers/Customers/tables";
import headers from "../../../Data/Tables/Customers/Customers/headers";
import Status from "../../../Componets/Status/status";
import customers from "../../../Data/customers.json";
import ActionButton from "../../../Componets/Actionbutton";

const CustomersTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async () => {
    try {
      let data = [];

      customers?.map((value, index) => {
        data?.push({
          Name: value?.name,
          Phone: value?.phone,
          Email: value?.email,
          Status: <Status status={value?.status} />,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default CustomersTable;
