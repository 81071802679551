import React from "react";
import { useNavigate } from "react-router-dom";
import AddButton from "../../Componets/Buttons/add";
import DotIcon from "../../Assets/Svg/dot";
import { FormOutlined } from "@ant-design/icons";
import AreaChart from "../../Componets/Areachart";
import Barchart from "../../Componets/Barchart";
import SalesIcon from "../../Assets/Svg/sales";
import PurchaseIcon from "../../Assets/Svg/purchase";
import ProductIcon from "../../Assets/Svg/products";
import InventoryIcon from "../../Assets/Svg/inventory";
import Piechart from "../../Componets/Piechart";
import billing from "../../Data/billing.json";
import Header from "../../Componets/Header";
import headers from "../../Data/Tables/Dashboard/Dashboard/headers";

const Dashboard = () => {
  let navigate = useNavigate();

  const cards = [
    {
      icon: <SalesIcon width={40} height={40} />,
      label: "Total Sales",
      value: "19754",
      color: "bg-violet-100",
      textcolor: "text-violet-600",
    },
    {
      icon: <PurchaseIcon width={40} height={40} />,
      label: "Total Purchase",
      value: "14850",
      color: "bg-green-100",
      textcolor: "text-green-600",
    },
    {
      icon: <ProductIcon width={40} height={40} />,
      label: "Total Product",
      value: "500",
      color: "bg-indigo-100",
      textcolor: "text-indigo-600",
    },
    {
      icon: <InventoryIcon width={40} height={40} />,
      label: "Out of stock",
      value: "10",
      color: "bg-red-100",
      textcolor: "text-red-600",
    },
  ];
  return (
    <Header header={headers}>
      <div className="flex justify-between">
        {cards?.map((value, index) => {
          return (
            <div className={`${value?.color} w-[20%] rounded-md p-6`}>
              <div className="flex justify-between">
                <div>
                  <div
                    className={`${value?.textcolor} text-4xl text-semi-bold`}
                  >
                    {value?.value}
                  </div>
                  <div className="text-gray-500 text-lg pt-2">
                    {value?.label}
                  </div>
                </div>
                <div className="flex items-center">
                  <div className={`${value?.textcolor} text-2xl`}>
                    {value?.icon}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex pt-8">
        <div className="w-[50%] bg-white rounded-md p-4">
          <div className="px-4">
            <div className="text-gray-500">Statastics</div>
            <div className="pt-1 text-lg text-medium">Total Sales</div>
          </div>
          <Barchart />
        </div>
        <div className="ml-4 w-[50%] bg-white rounded-md p-4">
          <div className="px-4">
            <div className="text-gray-500">Statastics</div>
            <div className="pt-1 text-lg text-medium">Inventory</div>
          </div>
          <Piechart />
        </div>
      </div>

      <div className="pt-8 pb-4">
        <div className="">
          <div className="pb-4 text-xl text-medium">Recent Payment</div>
          <table className="w-full rounded-lg overflow-hidden">
            <thead>
              <tr className="text-white bg-black border-b">
                <td className="p-3 first:rounded-tl-lg last:rounded-tr-lg">
                  Number
                </td>
                <td className="p-3">Date</td>
                <td className="p-3">Customer</td>
                <td className="p-3">Created</td>
                <td className="p-3">Payment</td>
                <td className="p-3 text-right first:rounded-tl-lg last:rounded-tr-lg">
                  Amount
                </td>
              </tr>
            </thead>
            <tbody>
              {billing
                ?.slice()
                ?.reverse()
                ?.map(
                  (value, index) =>
                    index <= 5 && (
                      <tr
                        className={`hover:bg-gray-200 bg-white border-b ${
                          index === billing?.length - 1
                            ? "last:rounded-bl-lg last:rounded-br-lg border-none"
                            : ""
                        }`}
                        key={index}
                      >
                        <td className="p-3">{value?.number}</td>
                        <td className="p-3">{value?.date}</td>
                        <td className="p-3">{value?.customer}</td>
                        <td className="p-3">{value?.created}</td>
                        <td className="p-3">{value?.payment}</td>
                        <td className="p-3 text-right">{value?.amount}</td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
      </div>
    </Header>
  );
};

export default Dashboard;
