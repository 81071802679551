import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Products/Products/forms";
import headers from "../../../../Data/Forms/Products/Products/headers";
import units from "../../../../Data/units.json";
import categories from "../../../../Data/category.json";
import brands from "../../../../Data/brands.json";

const ProductsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const fetchData = async () => {
    let units_options = [];

    units?.map((value, index) => {
      units_options?.push({ value: value?.name, label: value?.name });
    });

    let categories_options = [];

    categories?.map((value, index) => {
      categories_options?.push({ value: value?.name, label: value?.name });
    });

    let brands_options = [];

    brands?.map((value, index) => {
      brands_options?.push({ value: value?.name, label: value?.name });
    });

    values[0].options[2].form.options = units_options;
    values[1].options[0].form.options = categories_options;
    values[1].options[1].form.options = brands_options;

    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default ProductsForm;
