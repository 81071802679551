import React from "react";
import ReactApexChart from "react-apexcharts";

const Barchart = () => {
  const series = [
    {
      name: "Net Profit",
      data: [4400, 5500, 5700, 5600, 6100, 8800, 5899, 3200, 0, 0, 0, 0],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      fontFamily: "Manage Medium",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JULY",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
    },
    yaxis: {
      min: 0,
      max: 10000,
      tickAmount: 10,
      labels: {
        formatter: function (val) {
          return val / 1000 + "k";
        },
      },
      axisBorder: {
        show: true,
        color: "#E0E0E0",
        width: 1,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#E0E0E0",
        width: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },

    fill: {
      opacity: 1,
      colors: "#7c3aed",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "OMR " + val + " ";
        },
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
        border: 1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 10,
      },
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Barchart;
