const headers = {
  name: "Sale paid",
  navigate: {
    path: [
      {
        name: "Paid",
        path: "/app/sales/paid",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
