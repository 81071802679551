const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Supplier name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Product",
        type: "add",
        form: {
          mode: "multiple",
          name: "products",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Supplier product catlog",
          value: null,
          options: [],
          required: false,
          path: "/app/products/add",
        },
      },
    ],
  },
  {
    name: "Contacts",
    options: [
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Supplier phone",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Supplier email",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Supplier area",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Supplier city",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Supplier state",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Supplier country",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Numbers",
    options: [
      {
        name: "CR Number",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Supplier company registration number",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Tax Number",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Supplier vat number",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "License Number",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Supplier drug license number",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
