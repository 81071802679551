const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Customer name",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Contacts",
    options: [
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Customer phone",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Customer email",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Customer area",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Customer city",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Customer state",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Customer country",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
