const tables = [
  {
    name: "Number",
  },
  {
    name: "Sale",
  },
  {
    name: "Date",
  },
  {
    name: "Amount",
  },
  {
    name: "Payment",
  },
  {
    name: "Action",
  },
];

export default tables;
