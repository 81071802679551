import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import products from "../../../Data/products.json";
import units from "../../../Data/units.json";
import categories from "../../../Data/category.json";
import brands from "../../../Data/brands.json";

const ProductReport = () => {
  let location = useLocation();

  const [reports, setReports] = useState();

  const headers = {
    name: "Products",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "filter",
        name: "units",
        showSearch: true,
        placeholder: "Units",
        value: null,
        options: units?.map((value, index) => {
          return { value: value?.name, label: value?.name };
        }),
      },
      {
        type: "filter",
        name: "category",
        placeholder: "Category",
        showSearch: true,
        value: null,
        options: categories?.map((value, index) => {
          return { value: value?.name, label: value?.name };
        }),
      },
      {
        type: "filter",
        name: "brands",
        placeholder: "Brands",
        showSearch: true,
        value: null,
        options: brands?.map((value, index) => {
          return { value: value?.name, label: value?.name };
        }),
      },
    ],
  };

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = (tableData) => {
    let data = [];

    if (tableData) {
      tableData?.map((value, index) => {
        data?.push({
          name: value?.name,
          stock: value?.stock,
          units: value?.units,
          strips: value?.strips,
          tablets: value?.tablets,
          category: value?.category,
          brands: value?.brands,
        });
      });
    } else {
      products?.map((value, index) => {
        data?.push({
          name: value?.name,
          stock: value?.stock,
          units: value?.units,
          strips: value?.strips,
          tablets: value?.tablets,
          category: value?.category,
          brands: value?.brands,
        });
      });
    }

    setReports(data);
  };

  const get_unit = (id) => {
    let unit = "";
    units?.map((value, index) => {
      if (id == value?.id) {
        unit = value?.name;
      }
    });
    return unit;
  };

  const get_brand = (id) => {
    let brand = "";
    brands?.map((value, index) => {
      if (id == value?.id) {
        brand = value?.name;
      }
    });

    return brand;
  };

  const get_category = (id) => {
    let category = "";

    categories?.map((value, index) => {
      if (id == value?.id) {
        category = value?.name;
      }
    });
    return category;
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...products];

    let search = data?.search?.toLowerCase();
    let unit = data?.units;
    let category = data?.category;
    let brands = data?.brands;
    let date = data?.date;

    if (search) {
      tableData = tableData.filter((item) =>
        item?.name?.toLowerCase().includes(search)
      );
    }

    if (unit) {
      tableData = tableData.filter((item) => get_unit(item.units) === unit);
    }

    if (category) {
      tableData = tableData.filter(
        (item) => get_category(item.category) === category
      );
    }

    if (brands) {
      tableData = tableData.filter((item) => get_brand(item.brands) === brands);
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <table className="w-full bg-white">
        <thead className="text-gray-500">
          <tr>
            <td className="border p-4">Name</td>
            <td className="border p-4">Units </td>
            <td className="border p-4">Category</td>
            <td className="border p-4">Brands</td>
            <td className="border p-4">Stock</td>
            <td className="border p-4">Strips</td>
            <td className="border p-4">Tablets</td>
          </tr>
        </thead>
        <tbody>
          {reports?.map((value, index) => {
            return (
              <tr>
                <td className="border p-4">{value?.name}</td>
                <td className="border p-4">{get_unit(value?.units)}</td>
                <td className="border p-4">{get_category(value?.category)}</td>
                <td className="border p-4">{get_brand(value?.brands)}</td>
                <td className="border p-4">{value?.stock}</td>
                <td className="border p-4">{value?.strips}</td>
                <td className="border p-4">{value?.tablets}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.stock),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.strips),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.tablets),
                0
              )
              ?.toFixed(3)}
          </td>
        </tfoot>
      </table>
    </Header>
  );
};

export default ProductReport;
