const headers = {
  name: "Roles",
  navigate: {
    path: [
      {
        name: "Settings",
        path: "/app/settings",
      },
      {
        name: "Roles",
        path: "/app/settings/roles",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
