import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Inventory/Received/forms";
import headers from "../../../../Data/Forms/Inventory/Received/headers";
import products from "../../../../Data/products.json";
import { Checkbox, Input } from "antd";

const InventoryReceivedForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const fetchData = async () => {
    values[2].options[0].form = (
      <div>
        <table className="w-full">
          <thead>
            <tr>
              <td className="p-2 border">Status</td>
              <td className="p-2 border">Item</td>
              <td className="p-2 border">Qunatity</td>
            </tr>
          </thead>
          <tbody>
            {products?.map((value, index) => {
              return (
                <tr>
                  <td className="p-2 border text-center text-green-500">
                    {index == 3 || index == 7 || index == 6 ? "New" : ""}
                  </td>
                  <td className="p-2 border">{value?.name}</td>
                  <td className="p-2 border">
                    <Input type="number" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default InventoryReceivedForm;
