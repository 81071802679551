import React from "react";
import LogoutButton from "../Buttons/logout";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../../Assets/Svg/dashboard";
import ProductIcon from "../../Assets/Svg/products";
import BillingIcon from "../../Assets/Svg/billing";
import InventoryIcon from "../../Assets/Svg/inventory";
import SalesIcon from "../../Assets/Svg/sales";
import PurchaseIcon from "../../Assets/Svg/purchase";
import CustomerIcon from "../../Assets/Svg/customer";
import SupplierIcon from "../../Assets/Svg/supplier";
import BranchIcon from "../../Assets/Svg/branch";
import ReportIcon from "../../Assets/Svg/reports";
import ExpenseIcon from "../../Assets/Svg/expense";
import SettingIcon from "../../Assets/Svg/settings";

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const data = [
    {
      icon: <DashboardIcon />,
      name: "Dashboard",
      path: "/app/dashboard",
    },
    {
      icon: <ProductIcon />,
      name: "Products",
      path: "/app/products",
    },
    {
      icon: <InventoryIcon />,
      name: "Inventory",
      path: "/app/inventory",
    },
    {
      icon: <BillingIcon />,
      name: "Billing",
      path: "/app/billing",
    },
    {
      icon: <SalesIcon />,
      name: "Sales",
      path: "/app/sales",
    },
    {
      icon: <PurchaseIcon />,
      name: "Purchases",
      path: "/app/purchases",
    },
    {
      icon: <ExpenseIcon />,
      name: "Expenses",
      path: "/app/expenses",
    },
    {
      icon: <CustomerIcon />,
      name: "Customers",
      path: "/app/customers",
    },
    {
      icon: <SupplierIcon />,
      name: "Suppliers",
      path: "/app/suppliers",
    },
    {
      icon: <ReportIcon />,
      name: "Reports",
      path: "/app/reports",
    },
    {
      icon: <SettingIcon />,
      name: "Settings",
      path: "/app/settings",
    },
  ];

  return (
    <div className="border-r bg-white fixed left-0 top-0 bottom-0 z-50 w-64">
      <div className="p-4 overflow-y-auto h-full">
        <div className="mt-16">
          {data?.map((value, index) => {
            return (
              <div
                className={
                  location?.pathname?.includes(value?.path)
                    ? "my-2 cursor-pointer bg-[#07B553] rounded-lg"
                    : "my-2 cursor-pointer"
                }
                onClick={() => navigate(value?.path)}
              >
                <div
                  className={
                    location?.pathname?.includes(value?.path)
                      ? "flex p-2 text-white"
                      : "flex p-2 text-[#353F4F] hover:text-[#07B553]"
                  }
                >
                  <div>{value?.icon}</div>
                  <div className="px-2 my-auto">{value?.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full absolute bottom-0 left-0">
        <LogoutButton path="/" />
      </div>
    </div>
  );
};

export default Sidebar;
