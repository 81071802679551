const forms = [
  {
    name: "Product",
    options: [
      {
        name: "Image",
        type: "upload",
        form: {
          type: "text",
          name: "image",
          placeholder: "",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Product name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Unit",
        type: "add",
        form: {
          name: "unit",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Product unit",
          value: null,
          options: [],
          required: false,
          path: "/app/products/units/add",
        },
      },
    ],
  },
  {
    name: "Categorization",
    options: [
      {
        name: "Category",
        type: "add",
        form: {
          name: "category",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Product category",
          value: null,
          options: [],
          required: false,
          path: "/app/products/categories/add",
        },
      },
      {
        name: "Brand",
        type: "add",
        form: {
          name: "brand",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Product brand",
          value: null,
          options: [],
          required: false,
          path: "/app/products/brands/add",
        },
      },
    ],
  },
  {
    name: "Pack",
    options: [
      {
        name: "Strips",
        type: "input",
        form: {
          type: "number",
          name: "strips",
          placeholder: "Total strip's in one pack",
          value: "",
          required: false,
        },
      },
      {
        name: "Tablets",
        type: "input",
        form: {
          type: "number",
          name: "tablets",
          placeholder: "Total tablet's in one pack",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Stock",
    options: [
      // {
      //   name: "Type",
      //   type: "select",
      //   show: { check: true },
      //   form: {
      //     name: "is_stock",
      //     allowClear: false,
      //     placeholder: "Stockable",
      //     value: 1,
      //     options: [
      //       {
      //         value: 1,
      //         label: "Stockable",
      //       },
      //       {
      //         value: 0,
      //         label: "Non-stockable",
      //       },
      //     ],
      //     required: false,
      //   },
      // },
      {
        name: "Notify",
        type: "input",
        // show: { status: true, name: "is_stock" },
        form: {
          type: "number",
          name: "stock_notify",
          placeholder: "Product stock notification",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Expiry",
    options: [
      {
        name: "Notify",
        type: "input",
        form: {
          type: "number",
          name: "expiry_notify",
          placeholder: "Product expiry notification",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Barcode",
    options: [
      {
        name: "Barcode",
        type: "input",
        form: {
          type: "text",
          name: "barcode",
          placeholder: "Product barcode",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
