const forms = [
  {
    name: "Inventory",
    options: [
      {
        name: "Batch number",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Batch number",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Product",
        type: "add",
        path: "/app/products/add",
        form: {
          name: "product",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Product name",
          value: null,
          options: [],
          required: false,
        },
      },
    ],
  },
  {
    name: "Pricing",
    options: [
      {
        name: "Purchase Price",
        type: "input",
        form: {
          type: "number",
          name: "purchase_price",
          placeholder: "Product purchase price",
          value: "",
          required: true,
        },
      },
      {
        name: "Sale Price",
        type: "input",
        form: {
          type: "number",
          name: "sale_price",
          placeholder: "Product sale price",
          value: "",
          required: true,
        },
      },
      {
        name: "Tax",
        type: "select",
        show: { check: true },
        form: {
          name: "tax",
          allowClear: false,
          placeholder: "Product tax",
          value: 0,
          options: [
            {
              value: 0,
              label: "0%",
            },
            {
              value: 5,
              label: "VAT 5%",
            },
          ],
          required: false,
        },
      },
    ],
  },
  {
    name: "Stock",
    options: [
      {
        name: "Stock",
        type: "input",
        form: {
          type: "number",
          name: "stock",
          placeholder: "Product stock",
          value: "",
          required: false,
        },
      },
      {
        name: "Expiry date",
        type: "date",
        form: {
          name: "expiry_date",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Product expiry date",
          value: null,
          options: [],
        },
      },
      {
        name: "Barcode",
        type: "input",
        form: {
          type: "number",
          name: "barcode",
          placeholder: "Product barcode",
          value: "",
          required: false,
        },
      },
    ],
  },
];

export default forms;
