import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import sales from "../../../Data/sales.json";
import customers from "../../../Data/customers.json";

const SalesReport = () => {
  let location = useLocation();

  const [reports, setReports] = useState();

  const headers = {
    name: "Sales",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "filter",
        name: "customer",
        placeholder: "Customer",
        showSearch: true,
        value: null,
        options: customers?.map((value, index) => ({
          value: value?.name,
          label: value?.name,
        })),
      },
      {
        type: "filter",
        name: "payment",
        placeholder: "Payment",
        value: null,
        options: [
          { value: "Paid", label: "Paid" },
          { value: "Unpaid", label: "Unpaid" },
          { value: "Partial", label: "Partial" },
        ],
      },
      {
        type: "range",
        name: "date",
        value: {
          start: "",
          end: "",
        },
      },
      {
        type: "range",
        name: "due",
        value: {
          start: "",
          end: "",
        },
      },
    ],
  };

  const fetchData = (tableData) => {
    let data = [];

    if (tableData) {
      tableData?.map((value, index) => {
        data?.push({
          number: value?.number,
          customer: value?.supplier,
          date: value?.date,
          due: value?.due,
          payment: value?.paid,
          subtotal: value?.subtotal,
          tax: value?.tax,
          total: value?.amount,
        });
      });
    } else {
      sales?.map((value, index) => {
        data?.push({
          number: value?.number,
          customer: value?.supplier,
          date: value?.date,
          due: value?.due,
          payment: value?.paid,
          subtotal: value?.subtotal,
          tax: value?.tax,
          total: value?.amount,
        });
      });
    }

    setReports(data);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...sales];

    let search = data?.search?.toLowerCase();
    let customer = data?.customer;
    let payment = data?.payment;
    let date = data?.date;
    let due = data?.due;

    if (search) {
      tableData = tableData.filter((item) => item?.number?.includes(search));
    }

    if (customer) {
      tableData = tableData.filter((item) => item.supplier === customer);
    }
    if (payment) {
      tableData = tableData.filter((item) => item.paid === payment);
    }

    if (date && date.start && date.end) {
      const startDate = new Date(date.start);
      const endDate = new Date(date.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    if (due && due.start && due.end) {
      const startDate = new Date(due.start);
      const endDate = new Date(due.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.due);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <table className="w-full bg-white">
        <thead className="text-gray-500">
          <tr>
            <td className="border p-4">Invoice</td>
            <td className="border p-4">Customer</td>
            <td className="border p-4">Date</td>
            <td className="border p-4">Due</td>
            <td className="border p-4">Payment</td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4">Tax</td>
            <td className="border p-4">Amount</td>
          </tr>
        </thead>
        <tbody>
          {reports?.map((value, index) => {
            return (
              <tr>
                <td className="border p-4">{value?.number}</td>
                <td className="border p-4">{value?.customer}</td>
                <td className="border p-4">{value?.date}</td>
                <td className="border p-4">{value?.due}</td>
                <td className="border p-4">{value?.payment}</td>
                <td className="border p-4">{value?.subtotal}</td>
                <td className="border p-4">{value?.tax}</td>
                <td className="border p-4">{value?.total}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.subtotal),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.tax),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.total),
                0
              )
              ?.toFixed(3)}
          </td>
        </tfoot>
      </table>
    </Header>
  );
};

export default SalesReport;
