const forms = [
  {
    name: "Refund",
    options: [
      {
        name: "Purchase return",
        type: "select",
        form: {
          name: "purchase",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Select purchase",
          value: null,
          options: null,
          required: true,
        },
      },
      {
        name: "Date",
        type: "date",
        form: {
          type: "text",
          name: "name",
          placeholder: "Purchase paid date",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Amount",
    options: [
      {
        name: "Payemnt",
        type: "select",
        form: {
          name: "payment",
          allowClear: true,
          showSearch: true,
          filterOption: true,
          placeholder: "Select payment",
          value: null,
          options: [
            { value: "Cash", label: "Cash" },
            { value: "Cheque", label: "Cheque" },
            { value: "Credit card", label: " Credit card" },
            { value: "Debit card", label: "Debit card" },
            { value: "Bank transfer", label: "Bank transfer" },
            { value: "Online payment", label: "Online payment" },
          ],
          required: false,
        },
      },
      {
        name: "Amount",
        type: "input",
        form: {
          type: "number",
          name: "amount",
          placeholder: "Purchase paid amount",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
];

export default forms;
