const tables = [
  {
    name: "Name",
  },
  {
    name: "Batch",
  },
  {
    name: "Stock",
  },
  {
    name: "Strips",
  },
  {
    name: "Tablets",
  },
  {
    name: "Purchase",
  },
  {
    name: "Sale",
  },
  {
    name: "Tax",
  },
  {
    name: "Expiry",
  },
  {
    name: "Action",
  },
];

export default tables;
