const headers = {
  name: "Purchase return",
  navigate: {
    path: [
      {
        name: "Return",
        path: "/app/purchases/return",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
