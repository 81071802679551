import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Purchases/Order/forms";
import headers from "../../../../Data/Forms/Purchases/Order/headers";
import products from "../../../../Data/products.json";
import inventory from "../../../../Data/inventory.json";
import suppliers from "../../../../Data/suppliers.json";
import Purchasetable from "../../../../Componets/Purchasetable";

const PurchasesOrderForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = async () => {
    let supplier_options = [];
    suppliers?.map((value, index) => {
      supplier_options?.push({
        value: value?.name,
        label: value?.name,
      });
    });

    let item_options = [];
    inventory?.map((value, index) => {
      item_options?.push({
        value: value?.batch_number,
        label: get_product(value?.product) + " (" + value?.batch_number + ") ",
      });
    });

    let product_options = [];
    inventory?.map((value, index) => {
      product_options?.push(value);
    });

    values[0].options[0].form.options = supplier_options;
    values[1].options[0].form = <Purchasetable />;
    // values[1].options[0].form.options = product_options;
    // values[1].options[0].form.body[0].Description.form.options = item_options;
    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms
        width={"w-[70%]"}
        form={values}
        setForm={setValues}
        formdata={formdata}
      />
    </Header>
  );
};

export default PurchasesOrderForm;
