import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Products/Brands/tables";
import headers from "../../../../Data/Tables/Products/Brands/headers";
import brands from "../../../../Data/brands.json";
import ActionButton from "../../../../Componets/Actionbutton";
import Status from "../../../../Componets/Status/status";

const ProductbrandsTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const fetchData = async (tableData) => {
    try {
      let data = [];

      if (tableData) {
        tableData?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton edit={`/app/products/units/add`} delete="/" />
            ),
          });
        });
      } else {
        brands?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton edit={`/app/products/units/add`} delete="/" />
            ),
          });
        });
      }

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    let tableData = [...brands];

    let search = data?.search;
    let sort = data?.sort;
    let status = data?.status;

    if (search) {
      tableData = tableData.filter((item) =>
        item.name?.toLowerCase().includes(search)
      );
    }

    if (sort) {
      tableData.sort((a, b) => {
        if (sort === "1") {
          return a.name.localeCompare(b.name);
        } else if (sort === "2") {
          return b.name.localeCompare(a.name);
        }
        return 0;
      });
    }

    if (status || status == 0) {
      tableData = tableData.filter((item) => item.status === status);
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default ProductbrandsTable;
