import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import { toast } from "react-toastify";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Inventory/Stock/forms";
import headers from "../../../../Data/Forms/Inventory/Stock/headers";
import branches from "../../../../Data/branch.json";
import products from "../../../../Data/products.json";
import { Checkbox } from "antd";

const InventoryStockForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
    }
  };

  const handleData = (item) => {};

  const fetchData = async () => {
    let branches_options = [];
    branches?.map((value, index) => {
      branches_options?.push({
        value: value?.name,
        label: value?.name,
      });
    });

    values[1].options[0].form.options = branches_options;
    values[2].options[0].form = (
      <div>
        <table className="w-full">
          <thead>
            <tr>
              <td className="p-2 border">Check</td>
              <td className="p-2 border">Product</td>
              <td className="p-2 border">New</td>
            </tr>
          </thead>
          <tbody>
            {products?.map((value, index) => {
              return (
                <tr>
                  <td className="p-2 border">
                    <Checkbox defaultChecked />
                  </td>
                  <td className="p-2 border">{value?.name}</td>
                  <td className="p-2 border">
                    <Checkbox />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    setValues([...values]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Forms form={values} setForm={setValues} formdata={formdata} />
    </Header>
  );
};

export default InventoryStockForm;
